import moment from 'moment-timezone/builds/moment-timezone-with-data';

$(() => {
    const $window = $(window),
          $document = $(document),
          $htmlBody = $("html, body"),
          $carouseledProfileImage = $(".profile-image-container"),
          $carouselImageArrowLeft = $(".prof-arrow-left"),
          $carouselImageArrowRight = $(".prof-arrow-right"),
          $heroProfilePhoto = $(".profile-hero-container img"),
          $contactFormExpanded = $(".contact-form-expanded"),
          $contactFormSimple = $(".contact-form-simple"),
          $tooltips = $(".ui-tooltip"),
          $subjectQualificationOverlay = $("#subject-qualification-overlay"),
          $tutorCertificationOverlay = $("#tutor-certification-overlay"),
          $reportVideoButton = $(".confirm-report"),
          $reviewsSection = $("#reviews"),
          $reviewsAnchor = $(".reviews-anchor"),
          $ratingsAnchor = $(".ratings-anchor"),
          $ratingsAndReviewsTitle = $(".ratings-and-reviews-title"),
          $reviewsContainer = $(".reviews-container"),
          $readMore = $(".read-more"),
          $showMore = $(".show-more"),
          $videoCarouselButtonNext = $(".video-carousel-button-next"),
          $videoCarouselButtonPrev = $(".video-carousel-button-prev"),
          $segmentTracked = $(".segment-tracked"),
          loaderCode = `<div class='ajax-loader'>
                          <div class='wyz-loader wyz-loader-multi wyz-loader-large'>
                            <div class='cube cube-1'></div>
                            <div class='cube cube-2'></div>
                            <div class='cube cube-4'></div>
                            <div class='cube cube-3'></div>
                        </div>
                    </div>`;
    let siteFooterTop = 0,
        shiftedAvailability = {};

    const init = () => {
        // Set "sticky" items to fixed position on scroll
        const sticky = new Sticky("[data-sticky]");

        siteFooterTop = $("#site-footer")[0].offsetTop;

        bindEvents();
        bindWyzantModal();

        if (window.generalAvailability) {
            const utcOffsetInHours = new Date().getTimezoneOffset() / 60;
            shiftedAvailability = convertUTCAvailabilityToTimeZone(window.generalAvailability, utcOffsetInHours);
            convertAvailabilityToUserTime();
        }

        convertStartTimes();
    };

    const bindWyzantModal = function() {
        $("#report-video-modal").wyzantModal();
    };

    const bindEvents = () => {
        // Adjust contact elements on scroll
        $window.on("scroll", function() {
            const windowTop = $window.scrollTop(),
                  showExpanded = (windowTop > 300),
                  stickButton = ((windowTop + window.innerHeight) < siteFooterTop);

            $contactFormExpanded.toggleClass("hide", !showExpanded);
            $contactFormSimple.toggleClass("hide", showExpanded);
        }).trigger("scroll");

        $reportVideoButton.on("click", function(e) {
            e.preventDefault();
            let currentVideoId;

            if ($(".active-video-dot").length) {
                let activeVideoDot = $(".active-video-dot");
                currentVideoId = activeVideoDot.attr("data-youtube-id");
            } else {
                currentVideoId = $("#featuredVideoId").val()
            }

            let requestBody = {
                youtube_id: currentVideoId,
                tutor_user_id: $("#tutorId").val()
            }

            let requestToken = $('meta[name=csrf-token]').attr('content');

            fetch('/report_video', {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8',
                    'X-CSRF-Token': requestToken
                },
                method: 'POST',
                credentials: 'same-origin',
                body: JSON.stringify(requestBody)
            }).then(
              // Refresh the page
              location.reload()
            )
        });

        $videoCarouselButtonPrev.on("click", function() {
            let displayedVideo = $(".displayed-video");
            let activeVideoDot = $(".active-video-dot");
            let activeVideoDotIndex = activeVideoDot.attr("data-index")
            let nextVideoDotIndex = activeVideoDotIndex - 1
            let nextVideoDot = $(".video-carousel-dot-" + nextVideoDotIndex)

            // Check if we're at the left most video, if so, do nothing
            if (nextVideoDot.length == 0) {
                return;
            }

            // Update the displayed video to use the video id to the left
            // WZENG-6167 - append ?rel=0 to reccomend other tutor spotlights.
            displayedVideo.attr("src", "https://www.youtube.com/embed/" + nextVideoDot.attr("data-youtube-id") + "?rel=0")

            // Move the dot to the left
            activeVideoDot.removeClass("active-video-dot");
            nextVideoDot.addClass("active-video-dot");

        });

        $videoCarouselButtonNext.on("click", function() {
            let displayedVideo = $(".displayed-video");
            let activeVideoDot = $(".active-video-dot");
            let activeVideoDotIndex = activeVideoDot.attr("data-index")
            let nextVideoDotIndex = parseInt(activeVideoDotIndex) + 1
            let nextVideoDot = $(".video-carousel-dot-" + nextVideoDotIndex)

            // Check if we're at the right most video, if so, do nothing
            if (nextVideoDot.length == 0) {
                return;
            }

            // Update the displayed video to use the video id to the right
            displayedVideo.attr("src", "https://www.youtube.com/embed/" + nextVideoDot.attr("data-youtube-id") + "?rel=0")

            // Move the dot to the left
            activeVideoDot.removeClass("active-video-dot");
            nextVideoDot.addClass("active-video-dot");
        });

        $carouselImageArrowLeft.on('click', function() {
            let currentHeroDots = $('.active-indicator');
            let currentHeroIndex = parseInt(currentHeroDots.attr('data-prof-pic-idx'));
            let nextHeroIndex = currentHeroIndex - 1

            // we're at the furthest left picture
            if (currentHeroIndex == 0) {
                return;
            }

            let nextHeroImage = $(`#prof-pic-${nextHeroIndex}`).attr('src'),
                nextHeroDots = $(`[data-prof-pic-idx=${nextHeroIndex}]`);

            // if there's no new src, escape
            if (!nextHeroImage) {
                return;
            }

            currentHeroDots.removeClass('active-indicator');
            nextHeroDots.addClass('active-indicator');

            // First hide hero image, then fade it in for less jarring transition
            $heroProfilePhoto.hide();
            $heroProfilePhoto.attr('src', nextHeroImage).fadeIn(600);
        });

        $carouselImageArrowRight.on('click', function() {
            let currentHeroDots = $('.active-indicator');
            let currentHeroIndex = parseInt(currentHeroDots.attr('data-prof-pic-idx'));
            let nextHeroIndex = currentHeroIndex + 1

            let nextHeroImage = $(`#prof-pic-${nextHeroIndex}`).attr('src'),
                nextHeroDots = $(`[data-prof-pic-idx=${nextHeroIndex}]`);

            // we're at the furthest right picture or there's no new src
            if (nextHeroDots.length === 0 || !nextHeroImage) {
                return;
            }

            currentHeroDots.removeClass('active-indicator');
            nextHeroDots.addClass('active-indicator');

            // First hide hero image, then fade it in for less jarring transition
            $heroProfilePhoto.hide();
            $heroProfilePhoto.attr('src', nextHeroImage).fadeIn(600);
        });

        // Switch profile image when carousel item is clicked
        $carouseledProfileImage.on("click", function(e) {
            let $this = e.target,
                updatedHeroImage = $this.src,
                currentHeroImage = $heroProfilePhoto.attr("src");

            // First hide hero image, then fade it in for less jarring transition
            if (updatedHeroImage != currentHeroImage) {
                $carouseledProfileImage.removeClass("active-indicator");
                $this.parentNode.parentNode.classList.add("active-indicator");
                $heroProfilePhoto.hide();
                $heroProfilePhoto.attr("src", updatedHeroImage).fadeIn(600);
            }
        });

        $document.on("click", ".qual-tooltip-trigger", (e) => {
            e.preventDefault();
            openTooltip($(e.target.parentElement), false, false);
        });

        $document.on("click", ".banner-tooltip-trigger", (e) => {
            openTooltip($(e.target.parentElement), true);
        });

        $subjectQualificationOverlay.add($tooltips).on("click", (e) => {
            // Close the modal if the background is clicked
            if (e.target === e.currentTarget) {
                closeTooltips();
            }
        });

        $tutorCertificationOverlay.add($tooltips).on("click", (e) => {
            // Close the modal if the background is clicked
            if (e.target === e.currentTarget) {
                closeTooltips();
            }
        });

        $reviewsAnchor.on("click", (e) => {
            e.preventDefault();
            $htmlBody.animate({
                scrollTop: $reviewsSection.offset().top,
            }, 300);
        });

        $ratingsAnchor.on("click", (e) => {
            e.preventDefault();
            $htmlBody.animate({
                scrollTop: $ratingsAndReviewsTitle.offset().top,
            }, 300);
        });

        $readMore.on("click", (e) => {
            e.preventDefault();
            $(".truncated-content, .full-content").toggleClass("hide");
            $(".more-content").text(function(i, t) { return t === "Read more" ? "Read less" : "Read more"; });
        });

        $showMore.on("click", (e) => {
            e.preventDefault();
            $(".truncated-review, .full-review").toggleClass("hide");
            $(".more-review").text(function(i, t) { return t === "Read more" ? "Read less" : "Read more"; });
        });

        $reviewsSection.on("click", ".ui-page-link, .ui-page-navigation", (e) => {
            e.preventDefault();
            if (e.target.href !== undefined) {
                loadReviewContent(e.target.href);
            }
        });

        $window.on("wyzantModalShown", function() {
            // Checks the modal to see if scrolling should be enabled. Since
            // we can't get the original wyzantModal DOM element, we check the
            // whole page for a `wyzantModal-scrollable` element
            if (!document.getElementsByClassName("wyzantModal wyzantModal-scrollable")) {
                $htmlBody.addClass("no-scroll");
            }
        });

        $window.on("wyzantModalClosed", function() {
            $htmlBody.removeClass("no-scroll");
        });

        $('[data-show]').on('click', function(e) {
            e.preventDefault();
            let showId = $(this).attr('data-show');

            $('.full-text').hide();
            $(showId).removeClass('hide')
        });

        $('[data-hide]').on('click', function(e) {
            e.preventDefault();
            let hideId = $(this).attr('data-hide');

            $('.full-text').show();
            $(hideId).addClass('hide')
        });

        $segmentTracked.click(e => {
          const { eventName, eventPayload } = e.target.dataset;
          window.analytics.track(eventName, JSON.parse(eventPayload));
        });
    };

    const loadReviewContent = (url) => {
        showLoader($reviewsContainer);

        $.ajax({
            url: url,
            success: (reviewsContent) => {
                $htmlBody.animate({
                    scrollTop: $reviewsSection.offset().top,
                }, 300);

                hideLoader($reviewsContainer);
                $reviewsContainer.html(reviewsContent);
            },
            error: () => {
                hideLoader($reviewsContainer);
            }
        });
    };

    // used for subject description tooltips
    const openTooltip = ($triggerEl, isBanner) => {
        closeTooltips();

        $triggerEl.find(".ui-tooltip").show();
        if (isBanner) {
            $tutorCertificationOverlay.show();
        }
        else {
            $subjectQualificationOverlay.show();
        }
    };

    const closeTooltips = () => {
        $tooltips.hide();
        $subjectQualificationOverlay.hide();
        $tutorCertificationOverlay.hide();
    };

    const showLoader = ($target) => {
        $target.addClass("ui-overlay");
        $target.append(loaderCode);
    };

    const hideLoader = ($target) => {
        $target.removeClass("ui-overlay");
        $target.find(".ajax-loader").remove();
    };

    const convertUTCAvailabilityToTimeZone = (availabilityData, timezoneOffset) => {
        let keys = Object.keys(availabilityData),
            i = 0,
            l = keys.length,
            allAvailability = [],
            offsetHours;

        if (availabilityData === null) {
            return null;
        }

        // mash all the hours together
        for (; i < l; i++) {
            allAvailability = allAvailability.concat(availabilityData[keys[i]]);
        }

        // shift the array by the timezone offset
        offsetHours = allAvailability.splice(timezoneOffset);
        allAvailability = offsetHours.concat(allAvailability);

        // recreate daily hour blocks
        for (i = 0; i < l; i++) {
            availabilityData[keys[i]] = allAvailability.splice(0, 24);
        }

        return availabilityData;
    };

    const convertAvailabilityToUserTime = () => {
        // Loop through availability and apply offset
        let availability = shiftedAvailability;

        for (let day in availability) {
            let currDay = availability[day],
                rangeStartHour = null,
                hourData = [],
                hasAvailability = false,
                dayAvailabilityStrings = "";

            // Loop through array and find time ranges
            for (let hour = 0, totalHours = currDay.length; hour < totalHours; hour++) {
                let currHourAvailable = currDay[hour],
                    nextHour = hour + 1;

                // The current hour is available, determine if it's part of a range, or a single hour
                if (currHourAvailable === true) {
                  // Start tracking the range of time, using this hour as the beginning
                  if (rangeStartHour === null) {
                      rangeStartHour = hour;
                  }
                  // If the next hour is unavailable, or we've reached the end of the day
                  // Format the range and add it to our array for this day
                  if (currDay[hour + 1] === false || (nextHour === totalHours)) {
                      let formattedStartHour = formatFriendlyHour(rangeStartHour),
                          formattedNextHour = formatFriendlyHour(nextHour);
                      hourData.push(`${formattedStartHour} - ${formattedNextHour}`);
                  }
                  hasAvailability = true;
                }
                // The current hour is unavailable, reset the range beginning
                else {
                    rangeStartHour = null;
                }
            }
            // Combine the formatted hours and ranges for this day and update DOM
            dayAvailabilityStrings = hasAvailability ? hourData.join(", ") : "Unavailable";
            $(`[data-availability-day="${day}"]`).text(dayAvailabilityStrings);
        }

        $("#tutor-schedule .loading-content").hide();
        $(".availabilities-container").removeClass("hide");
    };

    const formatFriendlyHour = (hour) => {
        switch (hour) {
            case 0:
            case 24:
                return "Midnight";
            case 12:
                return "Noon";
            default:
                return `${hour % 12}:00 ${hour < 12 ? "am" : "pm"}`;
          }
    };

    const convertStartTimes = () => {
      $("[data-start-time-utc]").each((_i, el) => {
        const $el = $(el);
        const utcTime = $el.data("start-time-utc");
        const userTimeZone = $el.data("user-time-zone");
        const convertedTime = convertToTimeZone(utcTime, userTimeZone);
        const formattedTime = formatStartTime(convertedTime);

        $el.prepend(formattedTime);
      });
    };

    const convertToTimeZone = (dateString, timezoneName) => {
      const utcTime = moment(dateString);
      const timezone = timezoneName || moment.tz.guess();

      return utcTime.tz(timezone);
    }

    const formatStartTime = (startTime) => {
      const start = moment(startTime);
      const tomorrow = moment().add(1, 'day');
      const weekCutoff = moment().add(7, 'day').startOf('day');

      let formatString = null;
      if (start.isSame(moment(), 'day')) {
        formatString = 'h:mm a [Today]'; // Ex: 11:00 am Today
      } else if (start.isSame(tomorrow, 'day')) {
        formatString = 'h:mm a [Tomorrow]'; // Ex: 11:00 am Tomorrow
      } else if (start.isBefore(weekCutoff)) {
        formatString = 'h:mm a dddd'; // Ex: 11:00 am Monday
      } else {
        formatString = 'h:mm a MMM. D'; // Ex: 11:00 am Jun. 10
      }

      return start.format(formatString);
    }

    init();
});
